






















































































































































































import { appProvider } from '@/app-provider'
import { createPostController } from '@/modules/common/dialogs/create-post/create-post-controller'
import { postController } from '@/modules/common/dialogs/post/post-controller'
import { PostStore } from '@/stores/post-store'
import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Avatar from './avatar.vue'

@Observer
@Component({
  components: {
    Avatar,
    'post-grid-content': () => import('./post-grid-content.vue'),
    'follow-button': () => import('@/modules/common/components/follow-button.vue'),
  },
})
export default class PostGridItem extends Vue {
  @Prop({}) postStore?: PostStore
  @Prop({ default: 0 }) itemIndex?: number
  @Prop({ default: true }) enableRedirectPostDetail

  appProvider = appProvider
  walletStore = walletStore
  createPostController = createPostController
  postController = postController

  forceRedirect(postStore) {
    if (!postStore?.post?.isBlind) {
      this.$router.push(`/community/post/${postStore.post.id}`)
    }
  }

  redirectPostDetail(postStore) {
    // if (!this.walletStore.verifyUserAction()) return
    appProvider.router.push(`/community/post/${postStore.post.id}`)
  }

  async follow(postStore) {
    await postStore.followUser(postStore.post)
    this.$emit('syncFollowState', postStore)
  }

  async unFollow(postStore) {
    await postStore.unFollowUser(postStore.post)
    this.$emit('syncFollowState', postStore)
  }

  redirectTag(tagContent: string) {
    const searchTerm = tagContent.replaceAll('#', '')
    if (this.$router.currentRoute.path === '/search/forum') {
      this.$router.replace(`/search/forum?text=${searchTerm}`)
    } else {
      this.$router.push(`/search/forum?text=${searchTerm}`)
    }
    this.$forceUpdate()
  }
}
